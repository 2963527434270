.clear {
    clear: both;
}

.custom_file_upload {
    border: 1px solid #ccc;
    background-color: #0C2249;
    color: yellow;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

input[type="file"] {
    display: none;
}



.panel_udaju_container {
    height: auto;
    overflow: hidden;
    margin-top: 10px;
}

.panel_udaju_container .right {
    width: auto;
    overflow: hidden;
    font-weight: bold;
}

.panel_udaju_container .left {
    text-align:right;
    padding-right: 10px;
    width: 150px;
    float: left;      
}