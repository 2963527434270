


.detail_udaj_container {
    height: auto;
    overflow: hidden;
    line-height: 25px;
}

    .detail_udaj_container .right {
        width: auto;
        overflow: hidden;
        font-weight: bold;
    }

    .detail_udaj_container .rightdatetime {
        width: 190px;
        overflow: hidden;
        font-weight: bold;
    }


    .detail_udaj_container .left {
        text-align: right;
        padding-right: 10px;
        width: 150px;
        float: left;
        vertical-align:middle;
    }


.button_sekce {
    margin-right: 5px;
    margin-top: 5px;
    line-height: 14px;
    width: 105px;
    height: 40px;
    background-color: #E6F0F3;
    color: #204562;
    border: 2px solid #204562;
    float: left;
}
