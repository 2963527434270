
.detail_udaj_container {
    height: auto;
    overflow: hidden;
    line-height: 22px;
}

.detail_udaj_container .right {
    width: auto;
    overflow: hidden;
    font-weight: bold;
    padding-right: 7px;
}

.detail_udaj_container .right textarea {
    width: 100%
}


.detail_udaj_container .left {
    text-align: right;
    padding-right: 10px;
    width: 150px;
    float: left;
    vertical-align: middle;
}
