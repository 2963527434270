.mapcontainer {
    position: relative;
    width: 100%;
    height: 400px;
    box-shadow: 0 0 20px 0px #ffffff17;
    box-sizing: border-box;
    margin: 0;
}


.mapwrap {
    position: relative;
    width: 100%;
    height: 400px;
}

.map {
    position: absolute;
    width: 100%;
    height: 100%;
}

.mapresults {
    position: absolute;
    left: 1rem;
    top: 1rem;
    z-index: 111;
    background: #fff;
    line-height: 14px;
}

.mapresults p {
    margin-bottom: 4px;
    font-weight: bold;
}

.mapresults ul {
    padding: 5px;
    list-style: none;
    box-sizing: border-box;
    margin: 0;
}

.mapresults ul li:not(:last-child) {
    padding-bottom: 1rem;
    border-bottom: 1px solid #ccc;
    box-sizing: border-box;
    margin: 0;
}

.mapresults ul li:not(:first-of-type) {
    padding-top: 1rem;
    box-sizing: border-box;
    margin: 0;
}

.error_hledani {
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 100px;

    text-align: center;
    background-color: white;
    padding: 10px;
    z-index: 500;
}
