.detail_udaj_container {
    height: auto;
    overflow: hidden;
    line-height: 32px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.detail_udaj_container .right {
    width: auto;
    overflow: hidden;
    font-weight: bold;
}

.detail_udaj_container .right textarea {
    width: 100% ;
}

.detail_udaj_container .right_ta {
    width: auto;
    overflow: hidden;
    font-weight: bold;
}



.detail_udaj_container .right input[type="text"]:disabled {
    background: #dddddd;
    color: #dddddd;
}

.detail_udaj_container .right input[type="checkbox"] {
    height: 14px;
    width: 14px;
}

.detail_udaj_container .right input[type="checkbox"]:disabled {
    background: #dddddd;
}


.detail_udaj_container .left {
    text-align: right;
    padding-right: 10px;
    width: 150px;
    float: left;
    vertical-align: middle;
    font-weight:bold;
    line-height:42px;
}

.detail_udaj_container .left_ta {
    text-align: right;
    padding-right: 10px;
    width: 150px;
    float: left;
    vertical-align: middle;
}


.formHodnCislo {
    width: 100px;
    text-align:right;
}

@media screen and (max-width: 550px) {
    .detail_udaj_container .right_ta {
        width: 100%;
    }

    .detail_udaj_container .left_ta {
        text-align: left;

    }
}