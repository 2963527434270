.container {
    max-width: 1100px;
    /*margin: 0 auto;*/
    padding: 0 15px;
    display: flex;
    /*justify-content: space-between;*/
    align-items: center;
    height: 100%;
}

.navbar {
    height: 60px;
    background-color: #0C2249;
    position: relative;
    margin-left: 1%;
    margin-right: 1%;
}

.logo {
}

.menu-icon {
    display: none;
}

.nav-elements {
    z-index: 1000;
}

    .nav-elements ul {
        display: flex;
        justify-content: space-between;
        list-style-type: none;
    }

        .nav-elements ul li:not(:last-child) {
            margin-right: 30px;
        }

        .nav-elements ul a {
            font-size: 12px;
            font-weight: 400;
            color: #2f234f;
            text-decoration: none;
            background-color: #0C2249;
        }

            .nav-elements ul a.active {
                color: #574c4c;
                font-weight: 500;
                position: relative;
                background-color: white;
                font-weight: bold;
            }

                .nav-elements ul a.active::after {
                    content: '';
                    position: absolute;
                    bottom: -4px;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background-color: #574c4c;
                }

.odkaz_text {
    display: inline;
}

@media (max-width: 900px) {
    .odkaz_text {
        display: none;
    }
}



@media (max-width: 768px) {
    .nav-elements ul li:not(:last-child) {
        margin-right: 30px;
    }
}

@media (max-width: 600px) {
    .odkaz_text {
        display: inline;
    }

    .menu-icon {
        display: block;
        cursor: pointer;
    }

    .nav-elements {
        position: absolute;
        left: 0;
        top: 60px;
        background-color: #204562;
        width: 100%;
        height: 0; /*calc(100vh - 60px);*/
        transition: all 0.1s ease-in;
        overflow: hidden;
    }

        .nav-elements.active {
            height: 250px;
        }

        .nav-elements ul {
            display: flex;
            flex-direction: column;
        }

            .nav-elements ul li {
                margin-right: unset;
                margin-top: 22px;
            }
}




.menu_vyjezdaktual {
    position: absolute;
    right: 65px;
}

.menu_refresh {
    position: absolute;
    right: 15px;
}

.vyjezdaktual {
    float: right;
    line-height: 14px;
    text-align: left !important;
}

.vyjezdaktual a {
    line-height: 14px;
}

.container_vyjezdaktual {
    max-width: 300px;
    display: flex;
    line-height: 14px;
    height: 100%;
}

.container_vyjezdaktual_image {
    margin: 0 0 0 0;
    width: 22px;
    object-fit: contain;
    align-self: flex-start;
    padding-top: 8px !important;
}

.container_vyjezdaktual_text {
    padding-left: 5px !important;
    padding-top: 0px !important;
    flex: 1 1 auto;
    line-height: 14px;
    margin: auto !important;
}


@media screen and (max-width: 550px) {
    .menu_vyjezdaktual {
        position: unset;
    }

    .navbar {
        height: 80px;
    }

    .container_vyjezdaktual {
        max-width: 220px;
    }

    .vyjezdaktual {
        float:left;
    }
}

