.udrzbainfo {
    font-weight: bold;
    color: blue;
}


@keyframes flickerAnimation {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.2;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.2;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.2;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.2;
    }

    100% {
        opacity: 1;
    }
}

.udrzbaBezi {
    font-weight: bold;
    color: red;
    vertical-align: top;
}

.udrzbaBezi img {
    vertical-align: middle;
}



.udrzbavarovani {
    font-weight: bold;
    color: red;
    -webkit-animation: flickerAnimation 6s infinite;
    -moz-animation: flickerAnimation 6s infinite;
    -o-animation: flickerAnimation 6s infinite;
    animation: flickerAnimation 6s infinite;
}

