.modal__content_spinner {
    text-align: center;
    box-sizing: border-box;
}


.modal__backdrop_spinner {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background-color: transparent;
    align-items: center !important;
    justify-content: center !important;
    display: flex !important;
    box-sizing: border-box;
}


.modal__wrapper_spinner {
    position: relative;
    background-color: transparent;
    width: 400px;
    height: 200px;
    padding: 5px 20px 20px 20px;
}
